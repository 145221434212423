
#box {
    /* display: flex; */
    /* background:red; */
  }
  #box h1 {
    /* text-align: right; */
    /* font-size: 60px !important; */
    font-size: 3.0rem !important;
  }
  
  #box2 {
    /* width: 60%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    float: right;
    /* justify-content:flex-start;
    flex-direction: column; */
    /* background: red; */
  }
  
  #box2 object {
    height: 700px !important;
    width: 700px !important;
  }
  
  #box2 {
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #box-main {
    /* display: flex; */
    /* background: #000; */
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin-top: 100px !important;
    margin-bottom: 50px !important;

  }


  #box-main2 {
    /* display: flex; */
    /* background: #000; */
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin-top: 70px !important;
  }
  
  h1 {
    font-size: 20px !important;
  }
  
  
  
  #skills_div {
    /* background: #000; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  
  #jobs_div {
    margin-bottom: 50px;
  }
  
  #image_div {
    /* background: #000; */
    /* height: 100%; */
    width: auto;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    /* align-self: center; */
  }
  
  
   /* X-Small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {  }
  
  /* Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) {  }
  
  /* Medium devices (tablets, less than 992px) */
  
  /* Large devices (desktops, less than 1200px) */
  
  
  /* X-Large devices (large desktops, less than 1400px) */


  @media (min-width: 3001px) {

    #box-main2 {
      /* background: black; */
      /* height: 40vh;
      margin-top: 10px !important; */
     }
    #box-main {
      /* background: black; */
      /* height: 40vh;
      margin-top: 10px !important; */
     }
     
   
     h1 {
      /* text-align: right; */
      font-size: 45px !important;
    }
  
    #box p{
      font-size: 20px !important;
    }
  
    #box p{
      font-size: 17px !important;
  
    }
  
    #box-main h1 {
      /* text-align: right; */
      font-size: 4.0rem !important;
  
    }

    #box-main2 h1 {
      /* text-align: right; */
      font-size: 4.0rem !important;
  
    }
  
    #box2 object {
      height:650px !important;
      width: 650px !important;
     }
  
     
   }
  
  
  @media (min-width: 1920px)  and (max-width: 3000px) {
    #box-main {
      /* background: black; */
      /* max-height: 100%;
      min-height: 100vh !important;
      margin-top: 10px !important; */
     }

     #box-main2 {
      /* background: black; */
      /* min-height: 100vh !important; */
      /* margin-top: 10px !important; */
     }
     
   
     h1 {
      /* text-align: right; */
      font-size: 45px !important;
    }
  
    #box p{
      font-size: 20px !important;
    }
  
    #box p{
      font-size: 17px !important;
  
    }
  
    #box-main h1 {
      /* text-align: right; */
      font-size: 4.0rem !important;
  
    }
    #box-main2 h1 {
      /* text-align: right; */
      font-size: 5.0rem !important;
  
    }
  
    #box2 object {
      height:750px !important;
      width: 750px !important;
     }
  
     
   }
  
  /* Extra large screens, TV */
  
  @media (min-width: 1201px)  and (max-width: 1920px) and (max-height:1080px) {
    #box-main {
      /* background: black; */
      /* height: 100%;
      min-height: 100vh !important; */
     }

     #box-main2 {
      /* background: black; */
      /* height: 100vh !important; */
     }
     #box2 object {
      height:650px !important;
      width: 650px !important;
     }
  
     #box-main h1 {
       /* text-align: right; */
       font-size: 3.0rem !important;
  
     }

     #box-main2 h1 {
      /* text-align: right; */
      font-size: 3.0rem !important;
 
    }
     #box p{
       font-size: 18px !important;
     }
     #box p{
      font-size: 16px !important;
  
    }
  
     h1 {
       /* text-align: right; */
       font-size: 35px !important;
     }
  
     #box2{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /* background: #000; */
    
      }
  
    
  }
  
  /* Desktops, large screens */
  
  @media (min-width: 1025px) and (max-width: 1200px)  {
    #box-main {
      /* background: black; */

      /* height: 100%;
      min-height: 100vh !important; */

     }

     #box-main2 {
      /* background: black; */
      /* height: 100%;
      min-height: 100vh !important;
       */
     }
     #box2 object {
      height:520px !important;
      width: 520px !important;
     }
  
     #box-main h1 {
       /* text-align: right; */
       font-size: 3.0rem !important;
  
     }

     #box-main2 h1 {
      /* text-align: right; */
      font-size: 2.0rem !important;
 
    }
     #box p{
       font-size: 15px !important;
     }
  
     h1 {
       /* text-align: right; */
       font-size: 30px !important;
     }
  
  
    
  }
  
  /* Small screens, laptops */
  @media (min-width: 769px) and (max-width: 1024px)  {
    #box-main {
      /* background: black; */
  

      /* height: 100%;
      min-height: 100vh !important; */
     }

     #box-main2 {
      /* background: black; */
      /* height: 100vh; */
     }
     #box2 object {
      height:420px !important;
      width: 420px !important;
     }
  
     #box-main h1 {
       /* text-align: right; */
       font-size: 2.5rem !important;
  
     }

     #box-main2 h1 {
      /* text-align: right; */
      font-size: 2.5rem !important;
 
    }
     #box p{
       font-size: 15px !important;
     }
  
     h1 {
       /* text-align: right; */
       font-size: 25px !important;
     }
  
     #box2{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /* background: #000; */
    
      }
  
    
  }
  
  
  /* iPads, Tablets */
  
  @media (min-width: 481px) and (max-width: 768px)  {
    #box-main {
      /* background: black; */
     

      /* height: 100%;
      min-height: 100vh !important; */
     }

     #box-main2 {
      /* background: black; */
      /* height: 100vh; */
     
     }
     #box2 object {
      /* height:330px !important;
      width: 330px !important; */
     }
  
     #box-main h1 {
       /* text-align: right; */
       font-size: 2rem !important;
  
     }

     #box-main2 h1 {
       /* text-align: right; */
       font-size: 2rem !important;
  
     }
     #box p{
       font-size: 13px !important;
     }
  
     h1 {
       /* text-align: right; */
       font-size: 20px !important;
     }
  
     #box{
      order: 1;
      /* background: #000; */
  
    }
  
   
    
  }
  
  
  
  
  @media (min-width: 220px) and (max-width: 576px)  {
    #box-main {
      /* background: black; */
      /* height: 100vh; */
/*     
      height: 80%;
      min-height: 80vh !important; */
     }

     #box-main2 {
      /* background: black; */
      /* height: 100vh; */
      /* margin-top: 80px !important;
      height: 100%; */
     }
     #box2 object {
      height:350px !important;
      width: 350px !important;
     }
  
  
     #box2{
      /* background: black; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #000; */
  
    }
  
     #box-main h1 {
       /* text-align: right; */
       font-size: 2.2rem !important;
  
     }

     #box-main2 h1 {
       /* text-align: right; */
       font-size: 2.2rem !important;
  
     }
     #box p{
       font-size: 15px !important;
     }
  
     h1 {
       /* text-align: right; */
       font-size: 20px !important;
     }
     #box{
      order: 1;
      /* background: #000; */
  
    }
  }
  