


.error_message {
    font-size: 80%;
    color: rgb(234, 80, 80);
    margin-top: 10px;
    padding: 0px !important;
  }
  
  .error_div {
    border: 1px solid rgb(234, 80, 80);
    width: 100%;
  }
  