@import "../../../index.css";

#appnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

#appnav li {
  float: left;
}

#appnav li a {
  display: inline-block;
  color: #333333;
  text-align: center;
  padding: 10px 12px 10px 10px;
  text-decoration: none;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;

}

a.active:not(.app_nav_tile_btn a)::after{
  
}

#appnav a:not(.app_nav_tile_btn a)::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  border-radius: 10px;
  background: var(--primary_color);
  transition: width .5s;
  margin-top: 5px;
}

#appnav a:hover::after {
  width: 100%;
  /* //transition: width .3s; */
}

#appnav li a:hover {
  color: var(--primary_color) !important;
}

#appnav li:last-child a {
  padding: 10px 0px 10px 10px !important;
}

#appnav li a:hover:not(.active) {
  /* background-color: #111; */
}

.active {
  background-color: #04aa6d;
}

@media (min-width: 1201px) and (max-width: 1920px) {
  #appnav li a {
    /* font-size: 18px; */
    padding: 10px 13px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  #appnav li a {
    font-size: 16px;
    padding-right: 10px 12px 10px 10px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  #appnav li a {
    font-size: 14px;
    padding-right: 10px 12px 10px 0px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  #appnav li a {
    font-size: 12px;
    padding: 10px 12px;
  }
}

@media (min-width: 220px) and (max-width: 576px) {
}


@media print {
 
  #appnav,#app_mobile_nav,#homepage_footer {
      display: none !important ;
   }
  
}