.radio_label {
  /* background: #000; */
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radioDiv {
  /* background: red; */
  padding: 20px;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 4px;
  min-height: 180px;
  display: flex;
  /* align-items: center; */
}

.radioDiv input[type="radio"] {
  height: 25px;
  width: 25px;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 100%;
}

.radioDiv label,
.radioDiv {
  cursor: pointer;
}

.radioDiv span {
  font-size: 12px;
  color: rgb(71, 71, 71);
  margin-top: 20px;
}

.radio_div {
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioDiv h6{
    font-weight: 600 !important;
    font-size: 15px !important;
}
