.loginInputs{
    height: 20px !important;
    /* background: rgb(255, 255, 255) !important; */
    border-radius: 8px !important;
    border: 1px solid rgb(198, 198, 198) !important;
    font-size: 18px !important;
}

.auth_form .input_div {
    padding: 0px;
   
}

.auth_form input[type="text"],
.auth_form input[type="email"],
.auth_form input[type="number"]
.auth_form input[type="url"]
{
  height: 40px !important;
  background: white !important;
  border-radius: 0px;
  border: 0;

}

input[type='password'] {
    letter-spacing: 0.125em !important;
    font-weight: bold;
}


.loginInputs{
    height:40px !important;
}

.login-modal{
    /* background: #000 !important; */
    border-radius: 10px;
}


